import { graphql, navigate } from 'gatsby';
import React from 'react';
import { Layout } from '../components/Layout';
import { Project, ProjectData } from '../components/Project';
import { Seo } from '../components/Seo';
import { Image, isImage, isVideo } from '../components/Project';
import parse from 'html-react-parser';

type ProjectType = {
  project: ProjectData;
};

type SingleProjectData = {
  data: ProjectType;
};

const SingleProjectPage = ({ data }: SingleProjectData) => {
  const gallery = [data.project.featuredImage, ...data.project.gallery];
  return (
    <Layout>
      <Seo title={'Projects'} />
      <div className={'single-project-container'}>
        <div className='content'>
          <span
            className='close-btn'
            onClick={() => {
              navigate(-1);
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='52.047'
              height='52.046'
              viewBox='0 0 52.047 52.046'
            >
              <g
                id='Group_3'
                data-name='Group 3'
                transform='translate(0.001 0)'
              >
                <rect
                  id='Rectangle_11'
                  data-name='Rectangle 11'
                  width='4.445'
                  height='32.357'
                  transform='translate(-0.001 3.144) rotate(-45.006)'
                  fill='#fff'
                />
                <rect
                  id='Rectangle_12'
                  data-name='Rectangle 12'
                  width='4.445'
                  height='32.357'
                  transform='matrix(0.707, -0.707, 0.707, 0.707, 26.023, 29.166)'
                  fill='#fff'
                />
                <rect
                  id='Rectangle_13'
                  data-name='Rectangle 13'
                  width='32.358'
                  height='4.444'
                  transform='translate(0 48.903) rotate(-45)'
                  fill='#fff'
                />
                <rect
                  id='Rectangle_14'
                  data-name='Rectangle 14'
                  width='32.357'
                  height='4.445'
                  transform='matrix(0.707, -0.707, 0.707, 0.707, 26.023, 22.88)'
                  fill='#fff'
                />
              </g>
            </svg>
          </span>
          <div className='project-image-container'>
            {gallery.map((item: Image, idx) => (
              <React.Fragment key={idx}>
                {isImage(item.mime) && (
                  <>
                    <img
                      className='animated pop'
                      alt={item.alternativeText}
                      src={item.localFile.publicURL}
                    />
                  </>
                )}
                {isVideo(item.mime) && (
                  <>
                    <video
                      className='animated pop'
                      controls
                      playsInline
                      autoPlay={false}
                      muted={false}
                    >
                      <source src={item.localFile.publicURL} type={item.mime} />
                      Your browser does not support the video tag.
                    </video>
                  </>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className='project-description'>
          {/*   @ts-ignore */}
          {data.project.des && parse(data.project.des.description)}
        </div>
      </div>
    </Layout>
  );
};

export default SingleProjectPage;

export const query = graphql`
  query SingleProject($slug: String!) {
    project: strapiProject(slug: { eq: $slug }) {
      des: childStrapiProjectDescriptionTextnode {
        description
      }
      gallery {
        mime
        alternativeText
        localFile {
          publicURL
        }
      }
      title
      featuredImage: featured_image {
        mime
        localFile {
          publicURL
        }
      }
    }
  }
`;
